import { Auth } from "aws-amplify";
import { useRef, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { createService } from "../../graphql/mutations";
import { graphqlMutation } from "../../API/graphql";

async function getBusinessID() {
    const user = await Auth.currentAuthenticatedUser();
    return (user.attributes["custom:businessId"])
    // setBusinessID(user.attributes["custom:businessId"])
}


const handleFormSubmit = async (e) => {
    console.log("form submit")
}


function ServiceCreate({ onServiceCreate, onServiceClose,selectedStaff }) {
    const inputRef = useRef(null);
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [time, setTime] = useState('');
    const [note, setNote] = useState('');

    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [headerMessage, setHeaderMessage] = useState('')
    
    const handleClose = () => setShowResponseMessage(false);
    
    const handleShow = () => setShowResponseMessage(true);
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const user = await Auth.currentAuthenticatedUser();
        const businessID = user.attributes["custom:businessId"]

        console.log("staff id ", selectedStaff.id)
        const createServiceInput = {
            input: {
                name: name,
                price: price,
                time_mins: time,
                note: note,
                businessID: businessID,
                staffID: selectedStaff.id
            }
        };

        try {
            const result = await graphqlMutation(createService, createServiceInput);
            if (result.errors) {
                setHeaderMessage('Error')
                setMessage('Error creating service: ' + result.errors[0].message)
                handleShow()
            } else {
                onServiceCreate(result.data.createService)
            }

        } catch (err) {
            setMessage('Error creating service:' + err)
            handleShow()
        }
        // onServiceCreate(false);
    }


    const closeServiceCreate = (event) => {
        onServiceClose(false)
    }

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                <br />
                <Row className="mb-3">
                    <Col md="6">
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control autoFocus type="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="formPrice">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="number" placeholder="$" value={price} onChange={(e) => setPrice(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col md="3">
                        <Form.Group controlId="formTime">
                            <Form.Label>Time (minutes)</Form.Label>
                            <Form.Control type="number" placeholder="" value={time} onChange={(e) => setTime(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group controlId="formNote">
                            <Form.Label>Description</Form.Label>
                            <Form.Control rows={2} as="textarea" placeholder="Description.." value={note} onChange={(e) => setNote(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="right mb-3">
                    <Col className="right" md="3">
                        <Button  variant="contained"  color="error" size="small" className="me-2" onClick={closeServiceCreate}>
                            <CancelIcon />
                        </Button>
                        <Button  variant="contained"  color="primary" size="small" type="submit">
                            <SaveIcon />
                        </Button>
                    </Col>
                </Row>
            </Form>
            <Modal centered show={showResponseMessage} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{headerMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button  variant="contained"  color="error" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ServiceCreate
