import { API, graphqlOperation } from 'aws-amplify';


export const graphqlQuery = async (query, variables = {}) => {
  try {
    const response = await API.graphql(graphqlOperation(query, variables));
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const graphqlMutation = async (mutation, variables = {}) => {
  try {
    const response = await API.graphql(graphqlOperation(mutation, variables));
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};