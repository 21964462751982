import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
// import { withStyles } from '@material-ui/core/styles';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import { graphqlQuery, graphqlMutation } from '../../API/graphql';
import { listBusinesses } from '../../graphql/queries';
import { deleteBusiness } from '../../graphql/mutations';
import BusinessCreate from './create.business.component';
import BusinessEdit from './edit.business.component';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Row, Col, } from 'react-bootstrap';

function BusinessList({ classes }) {
    const [businesses, setBusinesses] = useState([]);
    const [showCreateBusiness, setShowCreateBusiness] = useState(false);
    const [showEditBusiness, setShowEditBusiness] = useState(false);
    const [selectedBusiness,setSelectedBusiness]=useState({})
    const [listIsLoading, setListIsLoading] = useState(true)
    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [headerMessage, setHeaderMessage] = useState('')
    const [message, setMessage] = useState('');

    const handleClose = () => setShowCreateBusiness(false);
    const handleShow = () => setShowCreateBusiness(true);

    const handleCloseEdit=()=>setShowEditBusiness(false);
    const handleShowEdit=()=>setShowEditBusiness(true);
    const handleCloseMessage = () => setShowResponseMessage(false);
    const handleShowMessage = () => setShowResponseMessage(true);

    const fetchData = async () => {
        const result = await graphqlQuery(listBusinesses, {
            filter: {}, // Filter out deleted items
        });
        if (result && result.listBusinesses && result.listBusinesses.items) {
            const notDeletedItems = result.listBusinesses.items.filter(items => items._deleted != true)
            setBusinesses(notDeletedItems);
        }
        setListIsLoading(false)
    };

    const handleFormCreateSubmit = (data) => {
        console.log(data)
        setHeaderMessage('Business Created')
        setMessage(data.name + ' was created successfully.')
        handleShowMessage()
        handleClose();
        fetchData()
    };

    const handleFormEditSubmit = (data) => {
        console.log(data);
        setHeaderMessage('Business Edited');
        setMessage(data.name + ' was edited successfully.');
        handleShowMessage();
        handleCloseEdit();
        fetchData();
    };


    useEffect(() => {
        fetchData();
    }, []);

    function handleEdit(business) {
        handleShowEdit(true)
        setSelectedBusiness(business)
    }
    const handleDelete = async (id, version) => {
        try {
            const response = await graphqlMutation(deleteBusiness, { input: { id, _version: version } });
            if (response && response.data && response.data.deleteBusiness) {
                // Remove the deleted business from the state
                setBusinesses((prevState) => prevState.filter((business) => business.id !== id));
                // Show a success notification
                toast.success("Business deleted successfully");
            } else {
                // Show an error notification
                toast.error("Failed to delete business");
            }
        } catch (error) {
            console.error(error);
            // Show an error notification
            toast.error("Failed to delete business");
        }
    };

    return (
        <>
            <Box height={50} />
            <Row>
                <h1>Business List</h1>

            </Row>
            <Button  variant="contained" color="primary" onClick={handleShow}>
                Create Business
            </Button>


            {/* <TableContainer component={Paper} > */}
            <div className="table-responsive">
                <Table striped bordered hover style={{ marginTop: 20 }} responsive>
                    <thead>
                        <tr>
                            <th >Name</th>
                            <th >Address</th>
                            <th >City</th>
                            <th >Country</th>
                            <th >Phone</th>
                            <th >Email</th>
                            {/* <th >Staff</th>
                            <th >Services</th>
                            <th >Hours</th>
                            <th >Bookings</th> */}
                            <th >Active</th>
                            <th >Created At</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            listIsLoading &&
                            <tr align="center">
                                <th colSpan={12} align="center">
                                    <Spinner animation="border" variant="primary" />
                                </th>
                            </tr>
                        }

                        {businesses.map((business) => (

                            <tr key={business.id}>
                                <th>{business.name}</th>
                                <th>{business.address}</th>
                                <th>{business.city}</th>
                                <th>{business.country}</th>
                                <th>{business.phone}</th>
                                <th>{business.email}</th>
                                {/* <th>{business.staff?.items}</th>
                                <th>{business.services?.items}</th>
                                <th>{business.hours?.items}</th>
                                <th>{business.bookings?.items}</th> */}
                                <th>{business.active ? 'Yes' : 'No'}</th>
                                <th>{business.createdAt}</th>
                                <td>
                                    <Button variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={() => handleEdit(business)}>
                                        <EditIcon />
                                    </Button>
                                </td>
                                <td>
                                     <Button variant="contained" 
                                        color="error" size="small"
                                        onClick={() => handleDelete(business.id, business._version)}
                                    >
                                        <DeleteIcon />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            {/* </TableContainer > */}


            <Modal size="lg" show={showCreateBusiness} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a New Business</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BusinessCreate onSubmit={handleFormCreateSubmit}></BusinessCreate>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-start">
                    <Button   variant="contained" color="error" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" show={showEditBusiness} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit a Business</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BusinessEdit selectedBusiness={selectedBusiness} onSubmit={handleFormEditSubmit}></BusinessEdit>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-start">
                    <Button  variant="contained" color="error" onClick={handleCloseEdit}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={showResponseMessage} onHide={handleCloseMessage}>
                <Modal.Header closeButton>
                    <Modal.Title>{headerMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button  variant="contained" color="error" onClick={handleCloseMessage}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default BusinessList;


//   https://www.youtube.com/watch?v=n3MBYwsyuL4