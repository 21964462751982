import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
// import { withStyles } from '@material-ui/core/styles';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.css';
import { graphqlQuery, graphqlMutation } from '../../API/graphql';
import { listStaff } from '../../graphql/queries';
import { deleteStaff } from '../../graphql/mutations';
// import BusinessCreate from './create.business.component';
import StaffCreate from './create.staff.component';
import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import ChecklistIcon from '@mui/icons-material/Checklist';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Row, Card, Spinner, Col } from 'react-bootstrap';
import StaffEdit from './edit.staff.component';
import StaffServices from './services.staff.component';
import { Storage } from 'aws-amplify';
import ServiceCreate from '../service/create.service.component';

function StaffList({ classes }) {
    const [staffs, setStaffs] = useState([]);
    const [showCreateStaff, setShowCreateStaff] = useState(false);
    const [showEditStaff, setShowEditStaff] = useState(false);
    const [showServicesStaff, setShowServicesStaff] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState({})
    const [listIsLoading, setListIsLoading] = useState(true)
    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [headerMessage, setHeaderMessage] = useState('')
    const [message, setMessage] = useState('');


    const handleClose = () => setShowCreateStaff(false);
    const handleShow = () => setShowCreateStaff(true);

    const handleCloseEdit = () => setShowEditStaff(false);
    const handleShowEdit = () => setShowEditStaff(true);

    const handleCloseServices = () => setShowServicesStaff(false);
    const handleShowServices = () => setShowServicesStaff(true);

    const handleCloseMessage = () => setShowResponseMessage(false);
    const handleShowMessage = () => setShowResponseMessage(true);

    const fetchData = async () => {
        const result = await graphqlQuery(listStaff, {
            filter: {}, // Filter out deleted items
        });
        if (result && result.listStaff && result.listStaff.items) {
            const notDeletedItems = result.listStaff.items.filter(items => items._deleted !== true)
            for (let index = 0; index < notDeletedItems.length; index++) {
                const staff = notDeletedItems[index];
                staff.picture = await Storage.get(staff.picture);

            }

            setStaffs(notDeletedItems);
        }
        setListIsLoading(false)
    };

    const handleFormCreateSubmit = (data) => {
        setHeaderMessage('Staff Created')
        setMessage(data.name + ' was created successfully.')
        handleShowMessage()
        handleClose();
        fetchData()
    };

    const handleFormEditSubmit = (data) => {
        setHeaderMessage('Staff Edited');
        setMessage(data.name + ' was edited successfully.');
        handleShowMessage();
        handleCloseEdit();
        fetchData();
    };


    useEffect(() => {
        fetchData();
    }, []);

    function handleEdit(staff) {
        handleShowEdit(true)
        setSelectedStaff(staff)
    }

    function handleServices(staff) {
        handleShowServices(true)
        setSelectedStaff(staff)
    }

    const handleDelete = async (id, version) => {
        try {
            const response = await graphqlMutation(deleteStaff, { input: { id, _version: version } });
            if (response && response.data && response.data.deleteStaff) {
                // Remove the deleted staff from the state
                setStaffs((prevState) => prevState.filter((staff) => staff.id !== id));
                // Show a success notification
                toast.success("Staff deleted successfully");
            } else {
                // Show an error notification
                toast.error("Failed to delete staff");
            }
        } catch (error) {
            console.error(error);
            // Show an error notification
            toast.error("Failed to delete staff");
        }
    };

    function formatDate(dateString) {
        const dateObj = new Date(dateString);
        return dateObj.toLocaleString("en-US", {
            month: "long",
            day: "numeric",
            year: "numeric",
            //   hour: "numeric",
            //   minute: "numeric",
            //   hour12: true,
        });
    }
    return (
        <>
            <Box height={50} />
            <Row>
                <h1>Staff List</h1>
            </Row>
            <Button variant="contained" color="primary" onClick={handleShow}>
                Create Staff
            </Button>

            <Row className="justify-content-center align-items-center">
                {
                    listIsLoading &&
                    <Col className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </Col>


                }
            </Row>

            <div className="d-flex flex-wrap">
                {staffs.map((staff) => (
                    <Card key={staff.id} style={{ width: "18rem", margin: "1rem" }}>
                        <Card.Img variant="top" src={staff.picture} style={{ height: "150px", objectFit: "cover" }} />
                        <Card.Body>
                            <Card.Title>{staff.name}</Card.Title>
                            <Card.Text>
                                {staff.phone} <br />
                                {staff.email} <br />
                                {formatDate(staff.createdAt)}
                            </Card.Text>
                            <center>
                                <Button variant="contained" color="success" size="small" onClick={() => handleEdit(staff)}>
                                    <EditIcon />
                                </Button>{" "}
                                <Button variant="contained"  color="primary" size="small" onClick={() => handleServices(staff)}>
                                    <ChecklistIcon />
                                </Button>{" "}
                                <Button
                                    variant="contained"  color="error" 
                                    size="small"
                                    onClick={() => {
                                        if (window.confirm("Are you sure you want to delete this staff member?")) {
                                            handleDelete(staff.id, staff._version);
                                        }
                                    }}
                                >
                                    <DeleteIcon />
                                </Button>
                            </center>

                        </Card.Body>
                    </Card>
                ))}
            </div>


            {/* Modal for create */}
            <Modal size="lg" show={showCreateStaff} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Create a New Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StaffCreate onSubmit={handleFormCreateSubmit}></StaffCreate>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-start">
                    <Button variant="contained"  color="error" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for edit */}
            <Modal size="lg" show={showEditStaff} onHide={handleCloseEdit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit a Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StaffEdit selectedStaff={selectedStaff} onSubmit={handleFormEditSubmit}></StaffEdit>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-start">
                    <Button variant="contained"  color="error" onClick={handleCloseEdit}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal for Services */}
            <Modal size="lg" show={showServicesStaff} onHide={handleCloseServices}>
                <Modal.Header closeButton>
                    <Modal.Title>Services</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <StaffServices selectedStaff={selectedStaff} onSubmit={handleFormEditSubmit}></StaffServices>
                </Modal.Body>

                <Modal.Footer className="d-flex justify-content-start">
                    <Button variant="contained" color="error" onClick={handleCloseServices}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal centered show={showResponseMessage} onHide={handleCloseMessage}>
                <Modal.Header closeButton>
                    <Modal.Title>{headerMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="contained" color="error" onClick={handleCloseMessage}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StaffList;