import { Auth, Storage } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { updateStaff } from "../../graphql/mutations";
import { graphqlMutation, graphqlQuery } from "../../API/graphql";
// import Button from 'react-bootstrap/Button';
import Button from "@mui/material/Button";
import Form from "react-bootstrap/Form";
import { Row, Col, Card, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { hashString } from "react-hash-string";
import ServiceCreate from "../service/create.service.component";
import { listServices } from "../../graphql/queries";
import Alert from "react-bootstrap/Alert";
import { CardActions, Fab } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
function StaffServices({ onSubmit, selectedStaff }) {
    const [id] = useState(selectedStaff.id);
    const [name, setName] = useState(selectedStaff.name);
    const [email, setEmail] = useState(selectedStaff.email);
    const [phone, setPhone] = useState(selectedStaff.phone);
    const [version] = useState(selectedStaff._version);
    const [services, setServices] = useState([]);
    const [file, setFile] = useState(null);
    const [createService, setCreateService] = useState(false);
    const [listIsLoading, setListIsLoading] = useState(true);
    const [listIsEmpty, setListIsEmpty] = useState(true);
    const [pictureEdited, setPictureEdited] = useState(false);
    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [message, setMessage] = useState("");
    const [headerMessage, setHeaderMessage] = useState("");

    const handleClose = () => setShowResponseMessage(false);
    const handleShow = () => setShowResponseMessage(true);

    const fetchData = async () => {
        const result = await graphqlQuery(listServices, {
            filter: { staffID: { eq: selectedStaff.id } }, // Filter out deleted items
        });
        if (result?.listServices?.items) {
            const notDeletedItems = result.listServices.items.filter(
                (items) => items._deleted !== true
            );
            setServices(notDeletedItems);
        }

        setListIsLoading(false);
    };

    useEffect(() => {
        setListIsEmpty(services.length === 0);
    }, [services]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const editStaffInput = {
            input: {
                id: id,
                name: name,
                email: email,
                phone: phone,
                _version: version, //ITS A REQUIRED FIELD!
            },
        };
        var fileName = "";
        var fileExtension = "";
        console.log("out ", pictureEdited);
        if (pictureEdited) {
            console.log("pictre", pictureEdited);
            fileName = file.name;
            fileExtension = fileName.split(".").pop();
            editStaffInput.input.picture =
                hashString(phone) + "." + fileExtension;
        }

        try {
            const result = await graphqlMutation(updateStaff, editStaffInput);

            if (result.errors) {
                setHeaderMessage("Error");
                setMessage("Error editing staff: " + result.errors[0].message);
                handleShow();
            } else {
                if (pictureEdited)
                    await Storage.put(
                        hashString(phone) + "." + fileExtension,
                        file
                    ); //TODO: optimice image before upload
                onSubmit(result.data.updateStaff);
            }
        } catch (err) {
            setMessage("Catched Error editing staff:" + err);
            handleShow();
        }
    };
    const handleShowCreateService = () => {
        setCreateService(!createService);
    };

    const handleServiceCreate = (value) => {
        // Do something with the value received from ServiceCreate
        console.log(value);
        setServices([...services, value]);
        // Change createService state
        setCreateService(false);
    };

    const handleServiceClose = (value) => {
        // Do something with the value received from ServiceCreate
        console.log(value);

        // Change createService state
        setCreateService(false);
    };

    return (
        <>
            <Row>
                <Col md="8">
                    <h5>Manage services of {name}</h5>
                </Col>
                {!createService && (
                    <Col md="4" className="right">
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleShowCreateService}
                        >
                            Add Service
                        </Button>
                    </Col>
                )}
            </Row>
            <br />
            <Row className="justify-content-center align-items-center">
                {listIsLoading && (
                    <Col className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                )}
            </Row>
            <Row>
                {listIsEmpty && (
                    <Col>
                        <Alert key="info" variant="info">
                            No services available
                        </Alert>
                    </Col>
                )}
            </Row>
            {listIsEmpty}

            {createService && (
                <Card border="primary">
                    <Card.Body>
                        <ServiceCreate
                            onServiceCreate={handleServiceCreate}
                            onServiceClose={handleServiceClose}
                            selectedStaff={selectedStaff}
                        />
                    </Card.Body>
                </Card>
            )}

            <div className="d-flex flex-wrap">
                {services.map((service) => (
                    <Card
                        className="card mb-3"
                        border="primary"
                        key={service.id}
                        style={{ width: "150px", margin: "1rem" }}
                    >
                        <Card.Header>
                            <Card.Title>{service.name}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            {service.price} <br />
                            {service.time} <br />
                            {service.note} <br />
                        </Card.Body>
                        <Card.Footer style={{ padding: 2 }}>
                            <Row className="justify-content-end">
                                <Col md="6">
                                    <Button size="small">
                                        <EditIcon />
                                    </Button>
                                </Col>
                                <Col md="6">
                                    <Button color="error" size="small" disabled>
                                        <VisibilityOffIcon />
                                    </Button>
                                </Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                ))}
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        marginTop: 32,
                        alignContent: "right",
                        position: "fixed",
                    }}
                >
                    Edit Services
                </Button>
            </div>

            <Modal centered show={showResponseMessage} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{headerMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StaffServices;
