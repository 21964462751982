import { Route, Routes } from 'react-router-dom';
import BusinessList from './components/business/list.business.component';
import StaffList from './components/staff/list.staff.component';
function BrowserRoutes() {
  return (
    <Routes>
      <Route exact path="/businesses" element={<BusinessList/>} />
      <Route exact path="/staffs" element={<StaffList/>} />
    </Routes>
  );
}

export default BrowserRoutes;