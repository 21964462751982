import { Auth, Storage } from 'aws-amplify';
import React, { useState } from 'react';
import { updateStaff } from '../../graphql/mutations';
import { graphqlMutation } from '../../API/graphql';
// import Button from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import { Row, Col, } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { hashString } from 'react-hash-string';

function StaffEdit({ onSubmit, selectedStaff }) {
    const [id] = useState(selectedStaff.id);
    const [name, setName] = useState(selectedStaff.name);
    const [email, setEmail] = useState(selectedStaff.email);
    const [phone, setPhone] = useState(selectedStaff.phone);
    const [version] = useState(selectedStaff._version);
    const [picture, setPicture] = useState(selectedStaff.picture);
    const [file, setFile] = useState(null);

    const [pictureEdited,setPictureEdited] = useState(false);
    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [headerMessage, setHeaderMessage] = useState('')
    

    const handleClose = () => setShowResponseMessage(false);
    const handleShow = () => setShowResponseMessage(true);

    const handleFileChange = (e) => {
        
        
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // Optional: You can also set the picture URL to a local preview of the image
        const reader = new FileReader();
        reader.onload = (event) => {
            setPicture(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
        setPictureEdited(true)
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const editStaffInput = {
            input: {
                id: id,
                name: name,
                email: email,
                phone: phone,
                _version: version //ITS A REQUIRED FIELD!
            }

        };
        var fileName=''
        var fileExtension=''
        console.log("out ",pictureEdited)
        if (pictureEdited) {
            console.log("pictre",pictureEdited)
            fileName = file.name;
            fileExtension = fileName.split('.').pop();
            editStaffInput.input.picture = hashString(phone) + '.' + fileExtension
        }

        try {
            const result = await graphqlMutation(updateStaff, editStaffInput);

            if (result.errors) {
                setHeaderMessage('Error')
                setMessage('Error editing staff: ' + result.errors[0].message)
                handleShow()
            } else {
                if(pictureEdited)
                    await Storage.put(hashString(phone)+'.'+fileExtension,file) //TODO: optimice image before upload
                onSubmit(result.data.updateStaff)
            }

        } catch (err) {
            setMessage('Catched Error editing staff:' + err)
            handleShow()
        }
    }

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        {picture && (
                            <div style={{ display: 'inline-block', width: '50px', height: '50px' }}>
                                <img src={picture} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        )}
                        <Form.Group controlId="formEmail">
                            <Form.Label>Picture</Form.Label>
                            {/* <Form.Control type="url" placeholder="https://s3" value={picture} onChange={(e) => setPicture(e.target.value)} /> */}
                            <Form.Control type="file" accept="image/*" onChange={handleFileChange} />

                        </Form.Group>
                    </Col>

                </Row>


                <Row style={{ marginTop: 15 }}>
                    <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>

                <div className="d-flex justify-content-end">
                    <Button  variant="contained" color="primary" type="submit" style={{ marginTop: 32, alignContent: 'right', position: 'fixed' }}>
                        Edit Staff
                    </Button>
                </div>

            </Form>

            <Modal centered show={showResponseMessage} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{headerMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button  variant="contained" color="error" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StaffEdit;