import "./App.css";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";

import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.css";
import Sidenav from "./sidenav";
import { BrowserRouter } from "react-router-dom";

Amplify.configure(awsExports);

function App() {
    return (
        <Authenticator>
            {({ signOut, user }) => (
                <BrowserRouter>
                    <Sidenav signOut={signOut} user={user}></Sidenav>
                </BrowserRouter>
            )}
        </Authenticator>
    );
}
export default App;
