/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://y4own2vthvc6lhrurikhdty4iy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-pwx763w4pjfezi6nqfcdbywlru",
    "aws_cognito_identity_pool_id": "us-east-1:099442f5-5546-4dcb-be92-a6a2323b4f6c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_PsCgkQj5C",
    "aws_user_pools_web_client_id": "4nhl6e4d3urlft9n8ver2ampei",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "staffs12916-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
