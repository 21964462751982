import { Auth, Storage } from 'aws-amplify';
import React, { useState } from 'react';
import { createStaff } from '../../graphql/mutations';
import { graphqlMutation } from '../../API/graphql';
// import Button from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import { Row, Col, Card} from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import {hashString} from 'react-hash-string';

async function getBusinessID() {
    const user = await Auth.currentAuthenticatedUser();
    return (user.attributes["custom:businessId"])
    // setBusinessID(user.attributes["custom:businessId"])
}

function StaffCreate({ onSubmit }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');

    const [picture, setPicture] = useState();
    const [file, setFile] = useState(null);

    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [headerMessage, setHeaderMessage] = useState('')

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);

        // Optional: You can also set the picture URL to a local preview of the image
        const reader = new FileReader();
        reader.onload = (event) => {
            setPicture(event.target.result);
        };
        reader.readAsDataURL(selectedFile);
    };

    const handleClose = () => setShowResponseMessage(false);
    const handleShow = () => setShowResponseMessage(true);
    
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const user = await Auth.currentAuthenticatedUser();
        const businessID = user.attributes["custom:businessId"]
        
        // SAVE PROFILE PICTURE
        const fileName = file.name;
        const fileExtension = fileName.split('.').pop();
        
        const createStaffInput = {
            input: {
                name: name,
                email: email,
                phone: phone,
                businessID: businessID,
                picture: hashString(phone)+'.'+fileExtension
            }
        };

        try {
            const result = await graphqlMutation(createStaff, createStaffInput);

            if (result.errors) {
                setHeaderMessage('Error')
                setMessage('Error creating staff: ' + result.errors[0].message)
                handleShow()
            } else {
                await Storage.put(hashString(phone)+'.'+fileExtension,file) //TODO: optimice image before upload
                onSubmit(result.data.createStaff)
            }

        } catch (err) {
            setMessage('Error creating staff:' + err)
            handleShow()
        }
    }

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        {picture && (
                            <div style={{ display: 'inline-block', width: '100px', height: '100px' }}>
                                <img src={picture} alt="Preview" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                            </div>
                        )}
                        <Form.Group controlId="formEmail">
                            <Form.Label>Picture</Form.Label>
                            {/* <Form.Control type="url" placeholder="https://s3" value={picture} onChange={(e) => setPicture(e.target.value)} /> */}
                            <Form.Control type="file" accept="image/*" onChange={handleFileChange} />

                        </Form.Group>
                    </Col>

                </Row>


                <Row style={{ marginTop: 15 }}>
                    <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>

                <div className="d-flex justify-content-end">
                    <Button  variant="contained"  color="primary" type="submit" style={{ marginTop: 32, alignContent: 'right', position: 'fixed' }}>
                        Create Staff
                    </Button>
                </div>

            </Form>

            <Modal centered show={showResponseMessage} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{headerMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button  variant="contained"  color="error" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default StaffCreate;