import { API, graphqlOperation } from 'aws-amplify';
import React, { Component, useEffect, useState } from 'react';
import { updateBusiness } from '../../graphql/mutations';
import { graphqlMutation } from '../../API/graphql';
// import Button from 'react-bootstrap/Button';
import Button from '@mui/material/Button';
import Form from 'react-bootstrap/Form';
import { Container, Row, Col, } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

function BusinessEdit({ onSubmit,selectedBusiness }) {
    const [id, setId] = useState(selectedBusiness.id);
    const [name, setName] = useState(selectedBusiness.name);
    const [address, setAddress] = useState(selectedBusiness.address);
    const [city, setCity] = useState(selectedBusiness.city);
    const [country, setCountry] = useState(selectedBusiness.country);
    const [email, setEmail] = useState(selectedBusiness.email);
    const [phone, setPhone] = useState(selectedBusiness.phone);
    const [active, setActive] = useState(selectedBusiness.active);
    const [stripeId, setStripeId] = useState(selectedBusiness.stripId);
    const [version, setVersion] = useState(selectedBusiness._version);
    const [showResponseMessage, setShowResponseMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [headerMessage,setHeaderMessage]=useState('')
    
    const handleClose = () => setShowResponseMessage(false);
    const handleShow = () => setShowResponseMessage(true);

    const handleActiveChange = () => {
        setActive(!active);
    };
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const editBusinessInput = {
            input: {
                id: id,
                name: name,
                address: address,
                city: city,
                country: country,
                email: email,
                phone: phone,
                active: active,
                stripe_id: stripeId,
                _version: version //ITS A REQUIRED FIELD!
            }

        };

        try {
            const result = await graphqlMutation(updateBusiness, editBusinessInput);

            if (result.errors) {
                setHeaderMessage('Error')
                setMessage('Error editing business: ' + result.errors[0].message)
                handleShow()
            } else {
                onSubmit(result.data.updateBusiness)
            }

        } catch (err) {
            setMessage('Error creating business:' + err)
            handleShow()
        }
    }

    return (
        <>
            <Form onSubmit={handleFormSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="name" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control type="address" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </Form.Group>
                    </Col>

                </Row>
                <Row style={{ marginTop: 15 }}>
                    <Col>
                        <Form.Group controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control type="city" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row style={{ marginTop: 15 }}>
                    <Col>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formPhone">
                            <Form.Label>Phone</Form.Label>
                            <Form.Control type="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>

                <Row style={{ marginTop: 15 }}>
                    <Col md="2">
                        <Form.Group controlId="formActive">
                            <Form.Label>Active</Form.Label>
                            <br />
                            <Form.Switch checked={active} onChange={handleActiveChange} size="lg" />
                        </Form.Group>
                    </Col>

                    <Col md="10">
                        <Form.Group controlId="formStripeId">
                            <Form.Label>Stripe ID</Form.Label>
                            <Form.Control type="text" placeholder="Stripe ID" value={stripeId} onChange={(e) => setStripeId(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>



                <div className="d-flex justify-content-end">
                    <Button  variant="contained" color="primary" type="submit" style={{ marginTop: 32, alignContent: 'right', position: 'fixed' }}>
                        Edit Business
                    </Button>
                </div>

            </Form>

            <Modal centered show={showResponseMessage} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{headerMessage}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button  variant="contained" color="error" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default BusinessEdit;